<template>
    <div style="display:flex; justify-content: center;width:100% !important;">
      <!-- <select v-model="cameraId" @change="load()">
        <option value="122">2342342</option>
        <option v-for="(device, key) in devices" :value="device.id" :key="key" >{{device.label }}</option>
      </select> -->
      <div class="my-5"  style="width:320px;" id="qr-code-full-region"></div>
    </div>
</template>
<script>
import {Html5Qrcode} from "html5-qrcode"

export default {
    props: {
    qrbox: {
      type: Number,
      default: 250
    },
    fps: {
      type: Number,
      default: 10
    },
    open : {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      devices : [],
      cameraId: null,
      html5QrCode:null
    }
  },
  watch : {
    open() {
      if (this.open) {
        this.init()
      } else {
        this.stopScan()
      }
    }
  },
  mounted () {
    this.html5QrCode = new Html5Qrcode("qr-code-full-region");
    this.init();
  },
  methods: {
    onScanSuccess (decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult);

    },
    init() {
        Html5Qrcode.getCameras().then(devices => {
            this.devices = devices
            if (devices && devices.length) {
              this.cameraId = devices[0].id;
              this.load()
              
            }
          }).catch(() => {
            // handle err
          });
    },
    stopScan() {
      this.html5QrCode.stop().then(() => {
            // QR Code scanning is stopped.
          }).catch(() => {
            // Stop failed, handle it.
          });
    },
    load () {
      const config = {
     fps: this.fps,
     qrbox: this.qrbox
   };
      
      this.html5QrCode.start(
      { facingMode: "environment" },
      // this.cameraId, 
      config,
      (decodedText, decodedResult) => {
        this.onScanSuccess(decodedText, decodedResult)
        this.stopScan()
          
      },
      () => {
      })
    .catch(() => {
    });
    }
  }
}
</script>