<template>
  <div>
    <v-dialog
    v-show="false"
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Scanner
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          QR Code Scanner
        </v-card-title>

        <v-card-text class="mt-5">
          <p class="error" v-if="error">{{ error }}</p>

          <p class="decode-result" v-if="result">Last result: <b>{{ result }}</b></p>
        </v-card-text>
        <v-divider></v-divider>
        <qrcode-scanner
          :qrbox="200" 
          :fps="10" 
          @result="onScan"
          :open="dialog"
        />
        <!-- <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
      </qrcode-stream> -->
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import qrcodeScanner from '@/components/qrcodeScanner';
export default {

components: { qrcodeScanner },

data () {
  return {
    error: '',
    dialog: false,
    camera: 'auto',
    result: null,
    showScanConfirmation: false
  }
},
watch : {
  dialog () {
    if(this.dialog) {
      this.unpause()
    } else {
      this.pause()
    }
  }
},

methods: {
  async onDecode (content) {
      this.result = content
      alert(this.result)
      console.log('this.result', this.result)
      this.dialog = false
      this.pause()
      await this.timeout(500)
    },

    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    async onInit (promise) {
      try {
        await promise
      } catch (e) {
        console.error(e)
      } finally {
        this.showScanConfirmation = this.camera === "off"
      }
    },
    onScan (decodedText) {
      alert(decodedText)
      this.dialog = false
    }
  }
}
</script>